import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

// import PDFDocument from "pdfkit";
// import blobStream from 'blob-stream';
import qs from 'qs';
import moment from 'moment';
import currency from 'currency.js';
import { PDFViewer } from '@react-pdf/renderer';

import {
  useLocation
} from "react-router-dom";

import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

Font.register({ family: 'Open Sans', src: 'https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0ef8pkAg.ttf' });
Font.register({ family: 'Open Sans Bold', src: 'https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhsKKSTjw.ttf' });

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ninjalearning.com.au/">
        jxeeno pty ltd (NinjaLearning)
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'rgb(255, 68, 0)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Create styles
const styles = StyleSheet.create({
    image: {
        width: 40
    },
  page: {
    flexDirection: 'column',
    padding: 50
    // backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  body: {
    fontSize: 10,
    marginVertical: 2,
    fontFamily: 'Open Sans'
  },
  bodySm: {
      fontSize: 9
  },
  row: {
    // flexGrow: 1,
    flexDirection: 'row',
  },
  fill: {
    flex: 1
  },
  hpad: {
    // flexGrow: 1,
    flexDirection: 'row',
    height: 30
  },
  hr: {
    borderBottom: 1,
    borderBottomColor: '#aaaaaa',
    marginVertical: 5
  },
  h1: {
    fontSize: 20,
    color: "#444444",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Open Sans Bold'
  },
  logoName: {
    fontSize: 22,
    color: "#444444",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Open Sans'
  },
  detailHeader: {
    width: 100
  },
  itemCol: {
    width: 60
  },
  itemIdCol: {
    width: 120
  },
  bold: {
    fontFamily: 'Open Sans Bold'
    // fontFamily: 'Arial Bold'
  }
});

// Create Document Component
const MyDocument = ({name, email, studentNo, invoiceSeq, items, note, paymentDate}: any) => {
  const invoiceNumber = `INV-${studentNo}-${invoiceSeq.padStart(3, '0')}`;
  const amount = (items as Item[]).map(item => item.qty * item.unitCost).reduce((a, b) => a + b, 0);
  const amtFormat = currency(amount).format();
  // const gstAmtFormat = currency((amount/1.1) * 0.1).format();

  return <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.row}>
        <View style={styles.image}>
            <Image src="/ninja-icon-512.png" />
        </View>
        <Text style={styles.logoName}>&nbsp;NinjaLearning</Text>
        <View style={styles.fill} />

        <View style={{alignItems: 'flex-end'}}>
          <View>
            <Text style={styles.body}>Ninja Learning Pty Ltd</Text>
          </View>
          <View>
            <Text style={[styles.body, styles.bold]}>NinjaLearning</Text>
          </View>
          <View>
            <Text style={styles.body}>classes@hscninja.com</Text>
          </View>
          <View>
            <Text style={styles.body}>ABN 74 653 354 646</Text>
          </View>
        </View>
      </View>
      <View style={styles.hpad} />
      <View>
        <Text style={styles.h1}>Invoice</Text>
      </View>
      <View style={styles.hr} />

      <View style={styles.row}>
        <View style={styles.fill}>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Invoice Number:</Text></View>
            <View><Text style={styles.body}>{invoiceNumber}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Invoice Date:</Text></View>
            <View><Text style={styles.body}>{moment().format('Do MMM YYYY')}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Due Date:</Text></View>
            <View><Text style={styles.body}>{moment(paymentDate).format('Do MMM YYYY')}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Balance Due:</Text></View>
            <View><Text style={styles.body}>{amtFormat}</Text></View>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Customer:</Text></View>
            <View>
              <View><Text style={styles.body}>{name}</Text></View>
              <View><Text style={styles.body}>{email}</Text></View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.hr} />
      <View style={styles.hpad} />
      <View style={styles.hpad} />
      <View style={styles.row}>
        <View style={styles.itemIdCol}><Text style={[styles.body, styles.bold]}>Item ID</Text></View>
        <View style={styles.fill}><Text style={[styles.body, styles.bold]}>Item Description</Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Unit cost</Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Qty</Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Line total</Text></View>
      </View>
      <View style={styles.hr} />
      {(items as Item[]).map((item, i) => <React.Fragment key={i}><View style={styles.row}>
        <View style={styles.itemIdCol}><Text style={styles.body}><Text style={styles.bold}>ITM-{studentNo}-{item.id}</Text></Text></View>
        <View style={styles.fill}><Text style={styles.body}>{item.description}</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{currency(item.unitCost).format()}</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{item.qty}</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{currency(item.unitCost * item.qty).format()}</Text></View>
      </View><View style={styles.hr} /></React.Fragment>)}
      {/* <View style={styles.hr} /> */}
      <View style={styles.row}>
        <View style={styles.fill}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Subtotal</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{amtFormat}</Text></View>
      </View>
      <View style={styles.row}>
        <View style={styles.fill}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Grand Total</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{amtFormat}</Text></View>
      </View>
      {/*<View style={styles.row}>
        <View style={styles.fill}><Text style={styles.body}></Text></View>
        <View style={styles.itemIdCol}><Text style={[styles.body, styles.bold]}>(GST Inclusive)</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{gstAmtFormat}</Text></View>
      </View>*/}
      <View style={styles.fill} />
      <View style={styles.hpad} />

      <View style={styles.hr} />
      <Text style={[styles.bodySm, styles.bold]}>
        Note
      </Text>
      <Text style={[styles.body, styles.bodySm]}>
        {note}
      </Text>
      
      <View style={styles.hr} />
      <Text style={[styles.bodySm, styles.bold]}>
        Make payments to:
      </Text>
      <View style={styles.row}>
        <View style={styles.fill}>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.bodySm, styles.bold]}>Account name:</Text></View>
            <View><Text style={styles.bodySm}>NINJA LEARNING PTY LTD</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.bodySm, styles.bold]}>BSB:</Text></View>
            <View><Text style={styles.bodySm}>062-692</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.bodySm, styles.bold]}>Account no.:</Text></View>
            <View><Text style={styles.bodySm}>4645 5912</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.bodySm, styles.bold]}>Payment reference:</Text></View>
            <View><Text style={styles.bodySm}>{studentNo}</Text></View>
          </View>
        </View>
      </View>
      <Text style={[styles.body, styles.bodySm]}>
        ** Please note: We have recently transferred our operations to Ninja Learning Pty Ltd and our bank account details have changed.
      </Text>
      <View style={styles.hr} />
      <Text style={[styles.bodySm, styles.bold]}>
        Refunds, cancellation and missed lesson policy:
      </Text>
      <Text style={[styles.body, styles.bodySm]}>
        We do not provide refunds or credits due to change of mind or missed lessons (such as sickness or holidays). If a lesson is missed and we are notified in advance, we will try to accomodate and organise reasonable requests to reschedule subject to availability of teachers and students at our discretion. If no rescheduled lesson can be organised, the lesson is considered cancelled. We will refund any lessons which were cancelled due to our unavailability at the original scheduled time.
      </Text>
    </Page>
  </Document>
};

interface Item {
    id: string;
    description: string;
    unitCost: number;
    qty: number;
}
const itemTypes: {[k: string]: string} = {
    T1: 'Term 1',
    T2: 'Term 2',
    T3: 'Term 3',
    T4: 'Term 4',
    T0: 'Trial lesson',
    X0: 'Trial lesson (discount)',
    H0: 'Summer holiday',
    H1: 'T1-T2 Holiday',
    H2: 'T2-T3 Holiday',
    H3: 'T3-T4 Holiday',
    X1: 'Other adjustment or discount',
    R0: 'Refund'
}

export function CustomerPdfApp() {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const classes = useStyles();

  const [name, setName] = useState(queryParams.name || 'Joe Citizen');
  const [studentNo, setStudentNo] = useState(queryParams.studentNo || '2020468');
  const [email, setEmail] = useState(queryParams.email || 'joe@citizen.com');

  const [invoiceSeq, setInvoiceSeq] = useState(queryParams.invoiceSeq || '001');
  const [paymentDate, setPaymentDate] = useState(moment().add(7, 'd').format('YYYY-MM-DD'));
  const [note, setNote] = useState(queryParams.note || 'Invoice for X x XX min weekly 1-on-1 XXX lessons at X:XXpm on Xxxxx.  Lessons from XXth Xxx 20XX to XXth Xxx 20XX inclusive at $XX/hr');

  const [items, setItems] = useState<Item[]>([{
      id: "T3",
      description: "Year 8 English - 1-on-1 (60 minutes)",
      unitCost: 66,
    qty: 3
  }]);

  const addItem = (item: Item) => {
    const newitems: Item[] = items.slice();
    newitems.push(item);
    setItems(newitems);
  }

  const deleteItem = (idx: number) => {
    const newitems: Item[] = items.slice();
    newitems.splice(idx, 1);
    setItems(newitems);
  }

  const setItemField = (val: any, i: number, key: string) => {
    const newitems: Item[] = items.slice();
    newitems[i] = {...newitems[i], [key]: val};
    setItems(newitems);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <PDFViewer style={{flex: 1, height: '100%', width: '100%'}}>
          <MyDocument name={name} items={items} studentNo={studentNo} invoiceSeq={invoiceSeq} email={email} note={note} paymentDate={paymentDate} />
        </PDFViewer>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src="/ninja-icon-512.png">
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            NinjaLearning - Customer Invoice Helper
          </Typography>
          <Typography component="p" variant="body1">
            You can use this tool to help generate invoices for NinjaLearning customers.
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Student name"
              name="name"
              autoFocus
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="studentNo"
              label="Student no"
              name="studentNo"
              value={studentNo}
              onChange={event => setStudentNo(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Student/Parent Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="paymentDate"
              label="Payment date"
              name="paymentDate"
              value={paymentDate}
              type="date"
              onChange={event => {
                  setPaymentDate(event.target.value)
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="invoiceSeq"
              label="Invoice Sequence"
              name="invoiceSeq"
              autoComplete="invoiceSeq"
              value={invoiceSeq}
              onChange={event => setInvoiceSeq(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
                rowsMax={4}
              id="note"
              label="Invoice note"
              name="note"
              autoComplete="note"
              value={note}
              onChange={event => setNote(event.target.value)}
            />

            <Button onClick={() => addItem({id: "X1", description: "Description", qty: 1, unitCost: 0})}>Add new item</Button>

            {items.map((item, i) => <Paper key={i} variant="outlined">
                <Button onClick={() => deleteItem(i)}>Delete item</Button>
                <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Item ID"
                select
                value={item.id}
                onChange={event => setItemField(event.target.value, i, "id")}
                >
                    {Object.keys(itemTypes).map(k => <MenuItem key={k} value={k}>
                            {itemTypes[k]}
                      </MenuItem>
                    )}
                </TextField>
                <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Item Description"
                multiline
                value={item.description}
                onChange={event => setItemField(event.target.value, i, "description")}
                />
                <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Unit cost"
                type="number"
                value={item.unitCost}
                onChange={event => setItemField(event.target.value, i, "unitCost")}
                InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                />
                <TextField
                variant="outlined"
                margin="normal"
                required
                label="Qty"
                type="number"
                fullWidth
                value={item.qty}
                onChange={event => setItemField(event.target.value, i, "qty")}
                />
            </Paper>)}


            <Typography component="p" variant="body1">
              Update the fields above, download/save the PDF.  Supports Chrome and Firefox, don't try on mobile cause' it won't work.
            </Typography>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
