import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// import PDFDocument from "pdfkit";
// import blobStream from 'blob-stream';
import qs from 'qs';
import moment from 'moment';
import currency from 'currency.js';
import { PDFViewer } from '@react-pdf/renderer';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { CustomerPdfApp } from './CustomerInvoice';
import { MenuItem } from '@material-ui/core';

Font.register({ family: 'Source Sans Pro', src: 'https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7gujVj9w.ttf' });
Font.register({ family: 'Source Sans Pro Bold', src: 'https://fonts.gstatic.com/s/sourcesanspro/v13/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdr3cOWxw.ttf' });

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ninjalearning.com.au/">
        jxeeno pty ltd (NinjaLearning)
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'rgb(255, 68, 0)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 50
    // backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  body: {
    fontSize: 10,
    marginVertical: 2,
    fontFamily: 'Source Sans Pro'
  },
  row: {
    // flexGrow: 1,
    flexDirection: 'row',
  },
  fill: {
    flex: 1
  },
  hpad: {
    // flexGrow: 1,
    flexDirection: 'row',
    height: 40
  },
  hr: {
    borderBottom: 1,
    borderBottomColor: '#aaaaaa'
  },
  h1: {
    fontSize: 20,
    color: "#444444",
    marginTop: 5,
    marginBottom: 5,
    fontFamily: 'Source Sans Pro Bold'
  },
  detailHeader: {
    width: 100
  },
  itemCol: {
    width: 60
  },
  bold: {
    fontFamily: 'Source Sans Pro Bold'
    // fontFamily: 'Arial Bold'
  }
});

// Create Document Component
const MyDocument = ({name, email, abn, amount, bsb, bankAccNo, paymentDate, customerName, customerAbn}: any) => {
  const invoiceNumber = `INV-${abn}-${moment(paymentDate).format('YYYYMMDD')}`;
  const amtFormat = currency(amount).format();

  return <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.row}>
        <View style={styles.fill} />
        <View style={{alignItems: 'flex-end'}}>
          <View>
            <Text style={styles.body}>{name}</Text>
          </View>
          <View>
            <Text style={styles.body}>{email}</Text>
          </View>
          <View>
            <Text style={styles.body}>ABN {abn}</Text>
          </View>
        </View>
      </View>
      <View style={styles.hpad} />
      <View>
        <Text style={styles.h1}>Invoice</Text>
      </View>
      <View style={styles.hr} />

      <View style={styles.row}>
        <View style={styles.fill}>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Invoice Number:</Text></View>
            <View><Text style={styles.body}>{invoiceNumber}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Invoice Date:</Text></View>
            <View><Text style={styles.body}>{moment(paymentDate).format('Do MMM YYYY')}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Due Date:</Text></View>
            <View><Text style={styles.body}>{moment(paymentDate).add(7, 'd').format('Do MMM YYYY')}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Balance Due:</Text></View>
            <View><Text style={styles.body}>{amtFormat}</Text></View>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Customer:</Text></View>
            <View>
              <View><Text style={styles.body}>{customerName || 'Ninja Learning Pty Ltd'}</Text></View>
              <View><Text style={styles.body}>invoices@hscninja.com</Text></View>
              <View><Text style={styles.body}>{customerAbn || 'ABN 74 653 354 646'}</Text></View>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.hr} />
      <View style={styles.hpad} />
      <View style={styles.hpad} />
      <View style={styles.row}>
        <View style={styles.fill}><Text style={[styles.body, styles.bold]}>Item Description</Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Unit cost</Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Qty</Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Line total</Text></View>
      </View>
      <View style={styles.hr} />
      <View style={styles.row}>
        <View style={styles.fill}><Text style={styles.body}>Supply of education services to NinjaLearning</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{amtFormat}</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>1</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{amtFormat}</Text></View>
      </View>
      <View style={styles.hr} />
      <View style={styles.row}>
        <View style={styles.fill}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Subtotal</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{amtFormat}</Text></View>
      </View>
      <View style={styles.row}>
        <View style={styles.fill}><Text style={styles.body}></Text></View>
        <View style={styles.itemCol}><Text style={[styles.body, styles.bold]}>Total</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>(excl GST)</Text></View>
        <View style={styles.itemCol}><Text style={styles.body}>{amtFormat}</Text></View>
      </View>
      <View style={styles.fill} />
      <View style={styles.hpad} />
      
      <View style={styles.hr} />
      <Text style={styles.body}>
        Make payments to:
      </Text>
      <View style={styles.row}>
        <View style={styles.fill}>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Account name:</Text></View>
            <View><Text style={styles.body}>{name}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>BSB:</Text></View>
            <View><Text style={styles.body}>{bsb}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Account no.:</Text></View>
            <View><Text style={styles.body}>{bankAccNo}</Text></View>
          </View>
          <View style={styles.row}>
            <View style={styles.detailHeader}><Text style={[styles.body, styles.bold]}>Payment reference:</Text></View>
            <View><Text style={styles.body}>{abn}</Text></View>
          </View>
        </View>
      </View>
    </Page>
  </Document>
};

function PdfApp() {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const classes = useStyles();

  const companies = [{name: "Ninja Learning Pty Ltd", abn: "ABN 74 653 354 646"}, {name: "JXEENO PTY LTD", abn: "ABN 53 609 018 322"}];
  const [ninjaAbn, setNinjaAbn] = useState(0);

  const [name, setName] = useState(queryParams.name || 'Joe Citizen');
  const [abn, setAbn] = useState(queryParams.abn || '9999999999');
  const [amount, setAmount] = useState(queryParams.amount || '25');
  const [email, setEmail] = useState(queryParams.email || 'joe@citizen.com');

  const [bsb, setBsb] = useState(queryParams.bsb || '123-456');
  const [bankAccNo, setBankAccNo] = useState(queryParams.bankAccNo || '12345678');

  const [paymentDate, setPaymentDate] = useState(moment().format('YYYY-MM-DD'));

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <PDFViewer style={{flex: 1, height: '100%', width: '100%'}}>
          <MyDocument name={name} abn={abn} email={email} amount={amount} bsb={bsb} bankAccNo={bankAccNo} paymentDate={paymentDate} customerName={companies[ninjaAbn].name} customerAbn={companies[ninjaAbn].abn} />
        </PDFViewer>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src="/ninja-icon-512.png">
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            NinjaLearning - Invoice Helper
          </Typography>
          <Typography component="p" variant="body1">
            You can use this tool to help generate invoices to charge NinjaLearning for services you've provided.
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Payee (Customer)"
                select
                value={ninjaAbn}
                helperText="From 20 September 2021, all invoices should be billed to Ninja Learning Pty Ltd"
                onChange={event => setNinjaAbn(parseInt(event.target.value))}
            >
                {Object.values(companies).map((k, i) => <MenuItem key={i} value={i}>
                        {companies[i].name}
                  </MenuItem>
                )}
            </TextField>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Your Name (or Company Name)"
              name="name"
              autoFocus
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="abn"
              label="Your ABN"
              name="abn"
              value={abn}
              onChange={event => setAbn(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Your Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="bsb"
              label="BSB"
              name="bsb"
              autoComplete="bsb"
              value={bsb}
              onChange={event => setBsb(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="bankAccNo"
              label="Bank Account Number"
              name="bankAccNo"
              autoComplete="bankAccNo"
              value={bankAccNo}
              onChange={event => setBankAccNo(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="amount"
              label="Billing amount"
              name="amount"
              value={amount}
              onChange={event => setAmount(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="paymentDate"
              label="Invoice date"
              name="paymentDate"
              value={paymentDate}
              type="date"
              onChange={event => {
                  setPaymentDate(event.target.value)
              }}
            />
            <Typography component="p" variant="body1">
              Update the fields above, download/save the PDF and email to <a href="mailto:invoices@hscninja.com">invoices@hscninja.com</a>.  Supports Chrome and Firefox, don't try on mobile cause' it won't work.
            </Typography>
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default function RoutedApp() {
  return <Router>

        <Switch>
          <Route path="/contractor">
            <PdfApp />
          </Route>
          <Route path="/customer">
            <CustomerPdfApp />
          </Route>
        </Switch>
    </Router>
}
